import React from 'react';
import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  filterChip: {
    textAlign: "start",
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(.5),
    },
  },
}));

const FiterChipStyle = ({ 
  filterText,
  refStyle,
  setRefStyle,
  styleSelected,
  setStyleSelected,
  i,
}) => {
  const handleClick = () => {
    setRefStyle(refStyle)
    if (styleSelected === '')
      setStyleSelected(refStyle)
    else 
      setStyleSelected('')
  };
  const classes = useStyles();
  return (
    <Chip 
      size="small"
      className={classes.filterChip}
      clickable 
      onClick={handleClick}
      variant={ styleSelected === refStyle ? "default" : "outined" }
      label={filterText} 
      color="primary"
    />
  );
};

export default FiterChipStyle;