import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FilterChipModel from './FilterChipModel';

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignContent: "start",
    marginBottom: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
    },
  },
  chips: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "row",
      flexWrap: "wrap",
      "& :not(:first-child)": {
        marginLeft: theme.spacing(.5),
      },
    },
  }
}));

const MiniFiltersModels = ({ setRefModel, modelSelected, setModelSelected }) => {
  const classes = useStyles();
  const models = [
    {refModel: "06", modelName: "Banana"},
    {refModel: "02", modelName: "Bolsa"},
    {refModel: "03", modelName: "Cabas-dos"},
    {refModel: "05", modelName: "Messager"},
    {refModel: "04", modelName: "Packados"},
    {refModel: "04-2", modelName: "Packados V2"},
    {refModel: "01", modelName: "Tote bag"},
    {refModel: "07", modelName: "Veste"},
    {refModel: "08", modelName: "Minidos"},
    {refModel: "09", modelName: "Carte cadeau"},

  ];

  return (
    <Grid 
      className={classes.container}
      container
      justifyContent="start"
      spacing={0}
    >
      <Grid item component="Typography">
        par modèle →
      </Grid>
      <Grid item className={classes.chips}>
        {models.map(({modelName, refModel},i) =>
          <FilterChipModel 
            index={`${modelName}-${i}`}
            filterText={modelName}
            refModel={refModel}
            setRefModel={setRefModel}
            modelSelected={modelSelected}
            setModelSelected={setModelSelected}
            key={modelName}
          />
        )}
      </Grid> 
    </Grid>
  );
};

export default MiniFiltersModels;