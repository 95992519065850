import React from 'react';
import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  filterChip: {
    marginBottom: theme.spacing(.5),
  }
}));

const FiterChipModel = ({ 
  filterText,
  refModel,
  setRefModel,
  modelSelected,
  setModelSelected,
  i,
}) => {
  const handleClick = () => {
    setRefModel(refModel)
    setModelSelected(refModel)
  };
  const classes = useStyles();
  return (
    <Chip 
      className={classes.filterChip}
      size="small"
      clickable 
      onClick={handleClick}
      variant={ modelSelected === refModel ? "default" : "outined" }
      label={filterText} 
      color="primary"
    />
  );
};

export default FiterChipModel;