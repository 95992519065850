import React from "react";
import { Link } from "gatsby";
import { makeStyles } from "@material-ui/core/styles";
import { 
  Button,
  Card, 
  Typography 
} from "@material-ui/core";
import gift_card from "../assets/images/0900-CarteCadeau-01.png";

const useStyles = makeStyles(theme => ({
  root: {
    textDecoration: "none",
  },
  card: {
    position: "relative",
    backgroundColor: "#ffffff",
    display: "flex",
    flexDirection: "column",
    borderRadius: 0,
    padding: theme.spacing(0),
    "&:hover" : {
      border: `1px solid ${theme.palette.primary.main}`,
      boxShadow: "none",
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(3),
    },
    // [theme.breakpoints.down('md')]: {
    //   height: theme.spacing(60)
    // },
  },
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: `${theme.spacing(0)}px ${theme.spacing(0)}px ${theme.spacing(0)}px`,
  },
  head: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "baseline",
    width: "100%",
    padding: `${theme.spacing(0)}px ${theme.spacing(1)}px`,
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(0.5)}px ${theme.spacing(0.5)}px`,
    },
  },
  head_title: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "baseline",
    "& h5": {
      fontSize: theme.spacing(2.25), 
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.spacing(1.75),
        paddingRight: theme.spacing(.5)  
      },
    },
    "& h6": {
      fontSize: theme.spacing(2), 
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.spacing(1.5),  
      },
    },
  },
  head_price: {
    [theme.breakpoints.down('sm')]: {
      borderLeft: "1px solid black",
      paddingLeft: theme.spacing(.5)
    },
  },
  price: {
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      fontSize: `${theme.spacing(1.5)}px !important`,
      fontWeight: "bold",
    },
  },
  heading_oldPrice: {
    textDecoration: "line-through",
    opacity: .5,
  },
  badge: {
    position: "absolute",
    zIndex: 1,
    top: "1%",
    right: "1%",
    borderRadius: 100,
    height: `${theme.spacing(5)}px`,
    width:`${theme.spacing(5)}px`,
    [theme.breakpoints.down('sm')]: { 
      height: `${theme.spacing(5)}px`,
      bottom: "87%",
      left: "75%",    }
  },
  badge_text: {
    position: "absolute",
    margin: `${theme.spacing(2.5)}px`,
    transform: "translate(-50%,-50%) ",
    verticalAlign: "middle",
    textAlign: "center",
  },
  badge_discount: {
    backgroundColor: theme.palette.orange.main,
  },
  badge_new: {
    backgroundColor: theme.palette.green.main,
    color: theme.palette.green.contrastText,
    fontStyle: "italic",
  },
  miniCard_media: {
    height: "auto",
    width: "100%",
  },
  content_container: {
    padding: `${theme.spacing(2)}px ${theme.spacing(0)}px ${theme.spacing(0)}px ${theme.spacing(0)}px`,
  },
  content: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "baseline"
  },
  button:{
    borderRadius: 0,
  },
  buttons_container: {
    margin: `${theme.spacing(2)}px 0`,
  },
}));

const MiniCardProduct = ({}) => {
  const classes = useStyles();
  return (
    <Link
      className={classes.root}
      to={`/product/gift-card`} 
      cover  
      direction="down"
      bg="#000000"
    > 
      <Card elevation={0} className={classes.card}>
        <div className={classes.header}>
            <img
              src={gift_card}
              className={classes.miniCard_media}
              alt={"illustration carte cadeau"}
            />
          <div className={classes.head}>
            <div className={classes.head_title}>
              <Typography variant="h5">Carte Cadeau</Typography>
            </div>
            <div className={classes.head_price}>
              <Typography className={classes.price} variant="subtitle1">15 € - 140 €</Typography> 
            </div>
          </div>
          <div className={classes.buttons_container}>
            <Button variant="outlined"> Choisir le montant</Button>
          </div>
        </div>
      </Card>
    </Link>
  );
};

export default MiniCardProduct;