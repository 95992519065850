import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { Link } from "gatsby";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Grid, Slide, Typography } from '@material-ui/core';
import clsx from 'clsx';
import Layout from '../components/Layout';
import ReadyMadeFilters from '../components/ReadyMadeFilters';
import MiniCardProduct from '../components/MiniCardProduct';
import MiniCardGiftCard from '../components/MiniCardGiftCard';
import { filteredArticles } from '../utils/filters';
import meche from '../assets/images/meche.svg';
import anim from '../assets/images/anim2.gif';

const useStyles = makeStyles(theme => ({
  subContainer: {
    margin: `${theme.spacing(0)}px ${theme.spacing(0)}px ${theme.spacing(4)}px`,
    textAlign: "start",
  },
  subContainer_title: {
    margin: `${theme.spacing(1)}px ${theme.spacing(0)}px ${theme.spacing(3)}px`,
    [theme.breakpoints.down('sm')]: {
      "& h2": {
        marginBottom: 0,
      },
    },
  },
  subContainer_pictures: {
    padding: `${theme.spacing(1)}px ${theme.spacing(0)}px ${theme.spacing(1)}px !important`,
    "& img": {
      width: "100%",
    },
    [theme.breakpoints.up('sm')]: {
      padding: `${theme.spacing(1)}px ${theme.spacing(0)}px ${theme.spacing(1)}px ${theme.spacing(1)}px !important`,
    },
  },
  subContainer_text: { 
    [theme.breakpoints.down('xs')]: {
      padding: `${theme.spacing(1)}px ${theme.spacing(0)}px ${theme.spacing(1)}px !important`,
    },
  },
  subContainer_action: {
    marginTop: `${theme.spacing(4)}px`,
  },
  intro_container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.up('lg')]: {
      justifyContent: "center",
      alignContent: "center",
    },
  },
  intro_text: {
    fontFamily: "Gilroy, SpcaceGrotesk, sans-serif",
    fontWeight: 700,
    "& h1": {
      fontSize: theme.spacing(7),
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.spacing(5),
      },
    },
    [theme.breakpoints.down('xs')]: {
      order: 2,
    },
  },
  intro_button: {
    width: "33%",
    margin: `${theme.spacing(3)}px ${theme.spacing(0)}px ${theme.spacing(0)}px`,
    [theme.breakpoints.down('xs')]: {
      margin: `${theme.spacing(3)}px 33% ${theme.spacing(0)}px`,
    },
  },
  container_offers: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down('xs')]: {
      flexDirection: "column"
    },
  },
  offers_title: {
    textAlign: "center",
    [theme.breakpoints.down('xs')]: {
      textAlign: "start",
    },
  },
  offer: {
    alignItems: 'stretch',
    margin: `${theme.spacing(3)}px ${theme.spacing(0)}px ${theme.spacing(0)}px`,
    "& h3": {
      textAlign: "center",
    },
    "& ul": {
      marginTop: theme.spacing(0),
      paddingLeft: theme.spacing(3),
    },
    [theme.breakpoints.down('md')]: {
      "& p": {
        padding: `${theme.spacing(0)}px ${theme.spacing(2)}px`,
      },
    },
    [theme.breakpoints.down('sm')]: {
      margin: `${theme.spacing(6)}px ${theme.spacing(0)}px ${theme.spacing(0)}px`,
      padding: `${theme.spacing(0)}px ${theme.spacing(1)}px`,
    },
    [theme.breakpoints.down('sx')]: {
      margin: `${theme.spacing(4)}px ${theme.spacing(0)}px ${theme.spacing(0)}px`,
      padding: `${theme.spacing(0)}px ${theme.spacing(0)}px`,
    },
  },
  offer_title: {
    fontFamily: "SpaceGrotesk, Gilroy, sans-serif",
  },
  offer_image: {
    maxWidth: "100%",
    margin: `${theme.spacing(3)}px ${theme.spacing(0)}px ${theme.spacing(6)}px`,
    [theme.breakpoints.down('sm')]: {
      margin: `${theme.spacing(3)}px ${theme.spacing(0)}px ${theme.spacing(3)}px`,
    },
  },
  button: {
    marginTop: theme.spacing(2),
    textTransform: "uppercase",
  },
  hyperlink: {
    textDecoration: "none",
    fontWeight: 600,
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.yellow.main,
      color: theme.palette.yellow.contrastText,
    },
    "&:visited": {
      color: theme.palette.primary.main,
    },
  },
  container_models: {
    marginTop: theme.spacing(6),
  },
  models_subtitle: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      width: "75%",
      marginBottom: theme.spacing(6),
    },
  },
  result: {
    margin: `${theme.spacing(2)}px ${theme.spacing(0)}px ${theme.spacing(0)}px`,
    [theme.breakpoints.down('sm')]: { 
      margin: `${theme.spacing(0)}px ${theme.spacing(0)}px ${theme.spacing(3)}px`
    },
  }, 
  result_text: {
    padding: `${theme.spacing(.5)}px ${theme.spacing(0)}px ${theme.spacing(1.5)}px`,
    [theme.breakpoints.down('sm')]: { 
      padding: `${theme.spacing(0)}px ${theme.spacing(0)}px ${theme.spacing(.5)}px`
    },
  },
  result_separator: {
    width: "1.75vw",
    [theme.breakpoints.down('lg')]: { 
      width: "3vw",
      margin: `${theme.spacing(0)}px ${theme.spacing(0)}px`
    },
    [theme.breakpoints.down('md')]: { 
      width: "4vw",
      margin: `${theme.spacing(0)}px ${theme.spacing(0)}px`
    },
    [theme.breakpoints.down('sm')]: { 
      width: "5vw",
      margin: `${theme.spacing(1)}px ${theme.spacing(0)}px`
    },
    [theme.breakpoints.down('xs')]: { 
      width: "10vw",
    },
  },
  articles_container: {
    padding: theme.spacing(8),
    [theme.breakpoints.down('sm')]: { 
      padding: theme.spacing(0),
    },
  },
  article_container: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('md')]: { 
      marginBottom: theme.spacing(0),
    },
  },
  button_container: {
    marginTop: `${theme.spacing(4)}px`,
  },
  button: {
    [theme.breakpoints.down('md')]: {
      margin: `${theme.spacing(0)}px ${theme.spacing(0)}px ${theme.spacing(2)}px`,
    },
    [theme.breakpoints.down('sm')]: {
      margin: `${theme.spacing(4)}px ${theme.spacing(0)}px ${theme.spacing(0)}px`,
    },
  },
  button_primary: {
    textTransform: "uppercase",
    margin: `${theme.spacing(0)}px ${theme.spacing(4)}px ${theme.spacing(0)}px`,
  },
}));

const IndexPage = ({data}) => {
  const [refModel, setRefModel] = useState('');
  const [refStyle, setRefStyle] = useState('');
  // const [availableOnly, setAvailableOnly] = useState(false);
  const [modelSelected, setModelSelected] = useState('');
  const [styleSelected, setStyleSelected] = useState('');
  // const [availableOnlySelected, setAvailableOnlySelected] = useState();
  const theme = useTheme();
  const classes = useStyles();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const removeAllFilters = () => {
    setRefModel('');
    setRefStyle('');
    setModelSelected('');
    setStyleSelected('');
  };
  const articlesList = filteredArticles(data.allMdx.edges, refModel, refStyle);
  return (
    <Layout pageName="accueil">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className={clsx(classes.subContainer, classes.intro_container)}
        >
          <Grid item xs={12} sm={6} md={6} lg={6} xl={4} className={clsx(classes.subContainer_text, classes.intro_text)}>
            <Typography variant='h1' className={classes.intro_text}>
              Des fringues désuètes transformées en sacs trop chouettes !
            </Typography>
            <Typography variant='h5'>
              Découvre nos créations uniques et
              <Link
                role="button"
                className={classes.hyperlink}
                cover
                direction="bottom"
                top="entry"
                to="/faq"
                bg="black"
              >
               surcyclées* 
              </Link>fabriquées zonmé 🏠 à partir de vêtements chinés sur place, du toulousain 100% garantie ! 
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={5} lg={6} xl={6} className={classes.subContainer_pictures}>
            <Slide in direction="left" timeout={300} mountOnEnter unmountOnExit>
              <img
                src={anim}
                alt="surboom, c'est quoi ?"
              />
            </Slide>
          </Grid>
        </Grid> 
        <Grid 
          container
          spacing={0}
          direction="row"
          justifyContent="center"
          className={clsx(classes.subContainer, classes.container_offers)}
        >
          <Grid 
            item 
            xs={12} sm={9} md={8}
            className={classes.offers_title}
          >
            <Typography variant='h5'>
              Le site est en maintenance ; le paiement en ligne direct est temporairement désactivé. 
              <br />
              En attendant l'achat est toujours possible par l'envoi d'un lien de paiement sécurisé.            
            </Typography>
            <br />
            <Typography variant='h3'>
              Nos plus belles pièces sont ici 🤩
            </Typography>
            <div className={classes.result}>
            <img className={classes.result_separator} src={meche} alt="separator" />
            <Typography variant="h6" className={classes.result_text}>
              {`${articlesList.length} article${articlesList.length > 1 ? 's' : ''}`}
            </Typography>
            <img className={classes.result_separator} src={meche} alt="separator" />
          </div>
          </Grid>
          <Grid 
            container
            spacing={0}
            justifyContent="start"
            className={clsx(classes.subContainer, classes.container_offers)}
        >
          <Grid 
            item 
            xs={12} md={2}
          >
          <ReadyMadeFilters 
            refModel={refModel}
            setRefModel={setRefModel}
            modelSelected={modelSelected}
            setModelSelected={setModelSelected}
            refStyle={refStyle} 
            setRefStyle={setRefStyle}         
            styleSelected={styleSelected}
            setStyleSelected={setStyleSelected}
            removeAllFilters={()=>removeAllFilters()}
          />
          </Grid>
          <Grid 
            item 
            xs={12} md={10}
          >
            {articlesList.length < 1 
            ? (
              <>
                <Grid 
                    item 
                    xs={6} md={4} lg={4} 
                    className={classes.article_container}
                  >
                  <MiniCardGiftCard />
                </Grid>
                <Typography variant="h4" className={classes.heading_subText}>
                  On s'active pour que, très vite, tu puisses commander ton sac prêt-à-porter !
                </Typography>
              </>
            ) : (     
              <Grid 
                container
                spacing={1}
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                alignContent="flex-start"
                className={classes.articles_container}
              >
                <Grid 
                  item 
                  xs={6} md={4} lg={4} 
                  className={classes.article_container}
                >
                  <MiniCardGiftCard />
                </Grid>
                {articlesList.map(({
                    node: {
                      slug,
                      frontmatter: {
                        name,
                        refModel,
                        refStyle,
                        price,
                        discount,
                        isNew,
                        pictures,
                        inlineAvailable,
                        location
                      },
                    },
                  }) =>
                  <Grid 
                    item 
                    xs={6} md={4} lg={4} 
                    className={classes.article_container}
                    key={name}
                  >
                        <MiniCardProduct 
                          slug={slug}
                          name={name}
                          refModel={refModel}
                          refStyle={refStyle}
                          price={price}
                          discount={discount}
                          isNew={isNew}
                          pictures={pictures}
                          inlineAvailable={inlineAvailable}
                          location={location}
                        />
                      </Grid>
                    )}
                  </Grid>
                )}
              </Grid>
            </Grid>
          <div>
            <Typography variant='h5'>
              Tu ne trouves pas ton bonheur sur le shop ?
            </Typography>
            <Typography variant='h6'>
              Partage-nous ton besoin 
              <Link className={classes.hyperlink} to="/contact">
                ici
              </Link>. 
              On échange ensemble sur ton envie : 
              modèle, style de patchwork, couleurs…
              <br />
              Surboom s'occupe de trouver la matière première et de la fabrication.
              <br />
              Et toi, tu reçois le sac de tes rêves direct dans ta boîte aux lettres !
              <br />
              <br />
              Tu peux aussi commander une carte cadeau au montant de ton choix (de 15€ à 140€) ici 👉
              <Link
                role="button"
                className={classes.hyperlink}
                cover
                direction="bottom"
                top="entry"
                to="/product/gift-card"
                bg="black"
              >
               Carte cadeau 
              </Link>

            </Typography> 
          </div>
        </Grid>
      </Layout>
  );
};
export const readymadeQuery = graphql`
{
  allMdx(
    filter: {
      fileAbsolutePath: {
        regex: "/content/products/"
      }
    }
    sort: {
      fields: frontmatter___order,
      order: ASC
    }
  ) {
    edges {
      node {
        slug
        frontmatter {
          name
          price
          discount
          isNew
          refModel
          refStyle
          order
          inlineAvailable
          location
          pictures {
            absolutePath
            childImageSharp {
              fluid(maxWidth: 1500, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
}`
export default IndexPage;


// frontmatter: {
//   location: {
//     regex: "/stock|Tarées/"
//   }
// }
