export const filteredArticles = (articles, refModel, refStyle) => {
    let filteredArticles = articles;
    if(refModel !== ''){
      filteredArticles = filteredArticles.filter(article => article.node.frontmatter.refModel === refModel)
    }
    if(refStyle !== ''){
      filteredArticles = filteredArticles.filter(article => article.node.frontmatter.refStyle === refStyle)
    }
    return filteredArticles;
  };

