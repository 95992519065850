import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Typography } from '@material-ui/core';
import MiniFiltersStyles from './MiniFiltersStyles';
import MiniFiltersModels from './MiniFiltersModels';

const useStyles = makeStyles(theme => ({
  subContainer: {
    margin: `${theme.spacing(0)}px ${theme.spacing(0)}px ${theme.spacing(0)}px`,
    textAlign: "start",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "baseline",
  },
  button: {
    boxShadow: "none",
  },
  filters_container: {
    margin: `${theme.spacing(2)}px ${theme.spacing(0)}px`,
  },
  filters_title: {
    padding: `${theme.spacing(0)}px ${theme.spacing(2)}px ${theme.spacing(0)}px ${theme.spacing(1)}px !important`,
  }
}));

const ReadyMadeFilters = ({
  refModel, 
  setRefModel,
  refStyle, 
  setRefStyle,
  removeAllFilters,
  modelSelected,
  setModelSelected,
  styleSelected,
  setStyleSelected,
}) => {
  const classes = useStyles();

  return (
    <Grid 
      className={classes.subContainer}
      container
      spacing={0}
    >
      <Grid 
        container
        direction='row'
        justifyContent='baseline'
        spacing={2}
    >
      <Grid item className={classes.filters_title}>
        <Typography  variant="h5" >
          Filtrer
        </Typography>
      </Grid>
      <Grid
        item
        className={classes.button_container} 
      >
        <Button 
          className={classes.button} 
          variant="contained"
          color="primary"
          onClick={removeAllFilters}
        >
          Effacer les filtres
        </Button>
      </Grid>
      </Grid>
      <Grid
        item
        xs={12} sm={7} md={7} lg={7} xl={4}
        className={classes.filters_container}
      >
        <MiniFiltersModels 
          refModel={refModel} 
          setRefModel={setRefModel} 
          modelSelected={modelSelected} 
          setModelSelected={setModelSelected}
        />
        <MiniFiltersStyles 
          refStyle={refStyle} 
          setRefStyle={setRefStyle} 
          styleSelected={styleSelected} 
          setStyleSelected={setStyleSelected}
        />
      </Grid>
    </Grid>
  );
};
export default ReadyMadeFilters;