import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FilterChipStyle from './FilterChipStyle';

const useStyles = makeStyles(theme => ({
  container: {
    container: {
      display: "flex",
      flexDirection: "column",
      alignContent: "start",
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        flexDirection: "column",
      },
    },
  },
  chips: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "row",
      "& :not(:first-child)": {
        marginLeft: theme.spacing(.5),
      },
    },
  }
}));

const MiniFiltersStyles = ({ setRefStyle, styleSelected, setStyleSelected }) => {
  const classes = useStyles();
  const data = useStaticQuery(graphql`
    {
      allMdx(
        filter: {
          fileAbsolutePath: {
            regex: "/content/styles/"
          }
        },
        sort: {
          fields: frontmatter___refStyle
        }
      ) {
        edges {
          node {
            id
            frontmatter {
              filter
              refStyle
            }
          }
        }
      }
    }
  `);
  const models = data.allMdx.edges; 
  return (
    <Grid 
      className={classes.container}
      container
      justifyContent="start"
      spacing={0}
    >
      <Grid item component="Typography">
        par style →
      </Grid>
      <Grid item className={classes.chips}>
        {models.map(({
          node: {
            frontmatter : { 
              filter, 
              refStyle,
            },
          }
        }, i) =>
          <FilterChipStyle 
            index={`${filter}-${i}`}
            filterText={filter}
            refStyle={refStyle}
            setRefStyle={setRefStyle}
            styleSelected={styleSelected}
            setStyleSelected={setStyleSelected}
            i={i}
            key={filter}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default MiniFiltersStyles;